import {
  compact,
  includes,
  replace,
} from 'lodash-es'
import isTestEnv from 'd2/utils/isTestEnv'
import rg4js from './rg4js'
import type { Raygun } from './rg4js'

function stripProtocol (url: string) {
  return replace(url, /https?:\/\//, '')
}

export function init () {
  if (typeof window === 'undefined') return

  // @ts-expect-error custom window properties
  const isDevEnv: boolean = window.settingsEnv === 'development'
  if (!isTestEnv && !isDevEnv) {
    // @ts-expect-error custom window properties
    const cdnUrl: string | null | undefined = window.VYDIA_SETTINGS.CDN_URL
    // @ts-expect-error custom window properties
    const webpackDevServerUrl: string | null | undefined = window.VYDIA_SETTINGS.WEBPACK_DEV_SERVER_URL_JS

    rg4js('groupingKey', (payload, _stackTrace, _options) => {
      if (includes(payload.Details.Tags, 'failed_to_fetch')) return 'failed_to_fetch'
      if (includes(payload.Details.Tags, 'client_offline')) return 'client_offline'
      if (includes(payload.Details.Tags, 'HTTP401')) return 'HTTP401'
      if (includes(payload.Details.Tags, 'HTTP403')) return 'HTTP403'
      if (includes(payload.Details.Tags, 'HTTP502')) return 'HTTP502'
      if (includes(payload.Details.Tags, 'SortBy')) return `Sort Error: ${payload.Details.UserCustomData.operationName}`
      if (includes(payload.Details.Tags, 'GraphQL')) return `GraphQL Error: ${payload.Details.UserCustomData.graphQLErrors?.[0]?.message ?? payload.Details.UserCustomData.operationName}`
    })

    rg4js('options', {
      allowInsecureSubmissions: true,
      captureMissingRequests: false,
      captureUnhandledRejections: true,
      debugMode: isDevEnv,
      disableErrorTracking: false,
      disablePulse: false,
      ignore3rdPartyErrors: false,
      ignoreAjaxAbort: true,
      ignoreAjaxError: true,
      pulseIgnoreUrlCasing: false,
      pulseMaxVirtualPageDuration: 1_800_000,
      setCookieAsSecure: false,
      wrapAsynchronousCallbacks: true,
    })

    rg4js('whitelistCrossOriginDomains', compact([cdnUrl ? stripProtocol(cdnUrl) : null, webpackDevServerUrl ? stripProtocol(webpackDevServerUrl) : null]))
    // @ts-expect-error custom window properties
    rg4js('setVersion', window.VYDIA_VERSION)
    rg4js('setUser', {
      // @ts-expect-error custom window properties
      email: window.current_user.email,
      // @ts-expect-error custom window properties
      firstName: window.current_user.first_name,
      // @ts-expect-error custom window properties
      fullName: `${window.current_user.first_name} ${window.current_user.last_name}`,
      // @ts-expect-error custom window properties
      identifier: window.current_user.id,
      isAnonymous: false,
    })
    rg4js('withTags', ['js', 'd2'])
    // @ts-expect-error custom window properties
    rg4js('apiKey', window.VYDIA_SETTINGS.RAYGUN_API_KEY)
    rg4js('logContentsOfXhrCalls', true)
    rg4js('enableCrashReporting', true)
    rg4js('enablePulse', true)
    rg4js('noConflict', true)
  }
}

// @ts-expect-error documentReady is custom on window
if (typeof window !== 'undefined' && window.documentReady) {
  // @ts-expect-error documentReady is custom on window
  window.documentReady(init)
}

const raygun: Raygun = rg4js

export default raygun
